<template>
  <div>
    <v-card-text>
      <v-alert v-if="!documentReady" text type="info"
        >No hay data para mostrar</v-alert
      >
      <v-card v-else outlined class="white pa-2">
        <iframe
          id="frameID"
          style="border: 2px solid rgb(237, 237, 237)"
          width="100%"
          :height="window.height"
          :src="reportViewer"
        ></iframe>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-form ref="form" lazy-validation>
          <v-select
            v-model="selectedReport"
            item-text="Nombre"
            item-value="ID"
            hint="Filtro por reporte"
            placeholder="Seleccione el reporte"
            append-icon="keyboard_arrow_down"
            persistent-hint
            :items="listaReporte"
            :rules="reporteValidation"
            :disabled="processing"
          ></v-select>
          <v-select
            v-model="selectedPeriodo"
            item-text="Fecha"
            item-value="ID"
            hint="Filtro por periodo"
            placeholder="Seleccione el periodo"
            append-icon="keyboard_arrow_down"
            persistent-hint
            :items="listaPeriodos"
            :rules="corteValidation"
            :disabled="processing"
          ></v-select>
        </v-form>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="generate">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ReportesAPI from "@/api/reportes";
import { mapActions } from "vuex";
import { global } from "@/api/global";

let _baseurl = global.baseWebUrl + "mvc/Report/";

export default {
  data: () => {
    return {
      drawerRight: true,
      selectedPeriodo: null,
      selectedReport: null,
      selectedProducto: null,
      documentReady: true,
      processing: false,
      reportData: [],
      listaReporte: [],
      listaPeriodos: [],
      corteValidation: [(v) => !!v || "Debe seleccionar un corte"],
      reporteValidation: [(v) => !!v || "Debe seleccionar un reporte"],
      window: {
        width: 0,
        height: 0,
      },
      reportViewer: "",
      breadcrumb: [
        {
          text: "Reportes",
          disabled: true,
        },
        {
          text: "Créditos y Cobros",
          disabled: true,
        },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    getReport() {
      if (this.$store.getters.user.canalid) {
        ReportesAPI.GetRiesgoReports()
          .then((response) => {
            this.listaReporte = response.data;
            this.documentReady = false;
          })
          .catch((error) => {
            this.setError(error);
            this.documentReady = false;
          });
      } else {
        this.setMessage("Favor seleccionar un Asociado primero");
      }
    },
    getPeriod() {
      ReportesAPI.GetRiesgoReportsEncabezado(this.selectedReport)
        .then((response) => {
          this.listaPeriodos = response.data;
          this.documentReady = false;
        })
        .catch((error) => {
          this.setError(error);
          this.documentReady = false;
        });
    },
    generate() {
      if (this.$refs.form.validate()) {
        this.documentReady = true;
        this.processing = true;
        this.reportViewer = global.baseWebUrl + "loader.html";

        var self = this;
        setTimeout(function () {
          self.processing = false;
          self.reportViewer =
            _baseurl +
            "ReporteRCAViewer?" +
            "encabezado=" +
            self.selectedPeriodo;
        }, 2000);
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 120;
    },
  },
  watch: {
    selectedReport: function () {
      this.getPeriod();
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.getReport();
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
tfoot {
  background: lightgray;
}
</style>